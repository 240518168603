import { SelectChangeEvent } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import FormItemContainer from '../common/FormItemContainer';
import Input from '../common/Input';
import Select from '../common/Select';

import { ECountryCode } from '@/constants/form';
import { cnMobilePhoneNumber, hongKongMobilePhoneNumber, isPhoneNumber } from '@/utils/validator';

const Container = styled.div`
	align-items: center;
	display: flex;
	gap: 16px;
	justify-content: space-between;
`;

const SelectContainer = styled.div`
	height: 40px;
	.MuiInputBase-root {
		background-color: #fff;
		border-radius: 2px;
	}
	.MuiSelect-select {
		font-size: 14px;
		height: 24px;
		padding: 8px 16px;
	}
	fieldset {
		border-color: #000;
	}
	.MuiSelect-icon {
		color: #000;
		font-size: 18px;
	}
	.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: #000 !important;
	}
`;

interface FormItemPhoneProps {
	required?: boolean;
	/** [countryCode, phone] */
	values: string[];
	onChange: (value: string[]) => void;
	onCheck?: (hasError: boolean) => void;
	placeholder?: string;
}

const FormItemPhone = ({
	values,
	required,
	onChange,
	onCheck,
	placeholder
}: FormItemPhoneProps) => {
	const [thisCountryCode, setThisCountryCode] = useState<ECountryCode>(ECountryCode.AUSTRALIA);
	const [thisPhone, setThisPhone] = useState<string>('');
	const [warning, setWarning] = useState<string>('');

	const handleCheck = (v: string[]) => {
		if (required && v[1] === '') {
			setWarning('输入不能为空');
			if (onCheck) {
				onCheck(true);
			}
			return;
		}
		if (v[1]) {
			let isValid = true;
			if (v[0] === ECountryCode.HONGKONG) {
				isValid = isPhoneNumber(v[1], hongKongMobilePhoneNumber);
			} else if (v[0] === ECountryCode.CHINA) {
				isValid = isPhoneNumber(v[1], cnMobilePhoneNumber);
			} else {
				isValid = isPhoneNumber(v[1]);
			}
			if (!isValid) {
				setWarning('请输入正确的手机号');
				if (onCheck) {
					onCheck(true);
				}
				return;
			}
		}
		setWarning('');
		if (onCheck) {
			onCheck(false);
		}
	};

	useEffect(() => {
		if (values[1] && (values[0] !== thisCountryCode || values[1] !== thisPhone)) {
			setThisCountryCode((values[0] as ECountryCode) || ECountryCode.AUSTRALIA);
			setThisPhone(values[1] || '');
			handleCheck(values);
		}
	}, [values]);

	const handleChange = (vs: string[]) => {
		setThisCountryCode(vs[0] as ECountryCode);
		setThisPhone(vs[1]);
		if (onChange) {
			onChange(vs);
		}
	};

	return (
		<FormItemContainer required={required} label="联系电话" warning={warning}>
			<Container>
				<SelectContainer>
					<Select
						value={thisCountryCode}
						options={Object.values(ECountryCode).map(key => {
							return {
								label: key,
								value: key
							};
						})}
						onChange={(e: SelectChangeEvent) =>
							handleChange([e.target.value, thisPhone])
						}
					/>
				</SelectContainer>
				<Input
					value={thisPhone}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange([thisCountryCode, e.target.value])
					}
					onBlur={(e: ChangeEvent<HTMLInputElement>) =>
						handleCheck([thisCountryCode, e.target.value])
					}
					warning={warning}
					placeholder={placeholder}
					type="tel"
				/>
			</Container>
		</FormItemContainer>
	);
};

export default FormItemPhone;
