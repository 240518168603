/**
 * Modal主题
 * - `default`: 默认简洁
 * - `primary`: 带主题边框
 */
export enum ModalTheme {
	Default = 'default',
	Primary = 'primary'
}

/**
 * 按钮类型
 * - `default`: 默认按钮
 * - `link`: 链接按钮
 */
export enum ButtonType {
	Default = 'default',
	Link = 'link'
}
export enum ButtonColor {
	Default = 'default',
	White = 'white',
	Beige = 'beige',
	Black = 'black',
	Blue = 'blue'
}

export default {};
