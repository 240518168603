import styled from 'styled-components';

import FeedbackContent from './FeedbackContent';
import Button from '@/components/UI/Button';
import { MODAL_STATUS } from '@/constants/common';
import { devices } from '@/styles/variables';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 24px 16px;
	text-align: center;
	width: 100%;
	@media ${devices.tablet} {
		padding: 32px 40px;
		max-width: 100%;
		width: 753px;
	}
`;

const ButtonContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 18px;
	justify-content: end;
	width: 100%;
`;

const Feedback = ({ status, onClose }: { status: MODAL_STATUS; onClose?: () => void }) => {
	return (
		<Container>
			<FeedbackContent status={status} />
			<ButtonContainer>
				<Button color="white" onClick={onClose}>
					关闭
				</Button>
			</ButtonContainer>
		</Container>
	);
};

export default Feedback;
