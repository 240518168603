import Image from 'next/image';
import styled, { keyframes } from 'styled-components';

import Button from '@/components/UI/Button';
import { GLOBAL_CONSULTATION } from '@/constants/domElement';
import imageLoader from '@/utils/loader';

const fadeInOut = keyframes`
  0%, 19% { opacity: 1; animation-timing-function: ease-out; }
  20%, 100% { opacity: 0; animation-timing-function: ease-in; }
`;

const TextAmination = styled.p`
	height: 14px;
	line-height: 1;
	margin: 0;
	width: 100px;
`;

const Text = styled.span`
	animation: ${fadeInOut} 10s infinite;
	display: flex;
	display: block;
	font-weight: 600;
	opacity: 0;
	position: absolute;
	transform: translateY(0); /* 确保文本垂直居中 */

	/* 为每个文本设置不同的动画延迟 */
	&:nth-child(1) {
		animation-delay: 0s;
	}
	&:nth-child(2) {
		animation-delay: 2s;
	}
	&:nth-child(3) {
		animation-delay: 4s;
	}
	&:nth-child(4) {
		animation-delay: 6s;
	}
	&:nth-child(5) {
		animation-delay: 8s;
	}
`;

const ConsultButton = ({ onClick }: { onClick: () => void }) => {
	return (
		<Button
			id={GLOBAL_CONSULTATION}
			onClick={onClick}
			color="lightblue"
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'fixed',
				bottom: 32,
				right: 32,
				zIndex: 100
			}}
		>
			<Image
				src="/image/home/consult-msg.svg"
				alt="consult"
				unoptimized
				loader={imageLoader}
				width={18}
				height={18}
				style={{
					marginRight: '8px'
				}}
			/>
			<TextAmination>
				<Text>1v1免费职业咨询</Text>
				<Text>1v1免费简历诊断</Text>
				<Text>1V1求职规划</Text>
				<Text>1V1职业技能提升</Text>
				<Text>1v1简历修改</Text>
			</TextAmination>
		</Button>
	);
};

export default ConsultButton;
