import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FormItemCom from '../../Form/Item/FormItemCom';
import FormItemName from '../../Form/Item/FormItemName';
import FormItemPhone from '../../Form/Item/FormItemPhone';

import Button from '@/components/UI/Button';
import { UserSource } from '@/constants/user';
import { fetchQuoteCreate } from '@/services/quote';
import { color, devices } from '@/styles/variables';
import imageLoader from '@/utils/loader';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 680px;
	max-width: 100vw;
	overflow-y: auto;
	@media ${devices.laptop} {
		min-width: 940px;
		flex-direction: row;
	}
`;

const IntroContainer = styled.div`
	// 手机模式隐藏介绍窗口
	display: none;

	@media ${devices.laptop} {
		padding: 34px 56px;
		background-color: ${color.primaryYellow};
		display: flex;
		flex-direction: column;
		max-width: 470px;
		width: 100%;
	}
`;
const MouseWrapper = styled.div`
	height: 612px;
	position: relative;
`;

const Highlight = styled.div`
	background-color: ${color.labelBgColorYellow};
	color: ${color.blackColor};
	font-family: Arial;
	font-size: 14px;
	height: 30px;
	line-height: 15px;
	margin: 20px 0 10px 0;
	padding: 8px;
	text-align: center;
	width: 170px;
`;

const TitleContainer = styled.div`
	align-items: center;
	display: flex;
	gap: 8px;
	margin: 0 0 28px 0;
`;

const Title = styled.p`
	font-size: 30px;
	font-weight: 600;
	margin: 0 8px 0 0;
`;

const Subtitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.4;
	margin: 0 0 38px 0;
`;

const InfoList = styled.div`
	align-items: start;
	display: flex;
	font-size: 14px;
	line-height: 1.71;
	margin-bottom: 33px;
`;

const MouseIcon = styled.div`
	bottom: 0;
	position: absolute;
	right: 0;
`;

const ConsultContainer = styled.div`
	height: 100%;
	max-width: 470px;
	overflow-y: auto;
	padding: 24px;
	width: 100%;

	@media ${devices.laptop} {
		padding: 42px 32px;
	}
`;

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;
const AggerDescription = styled.p`
	font-size: 14px;
	line-height: 14px;
	margin: 0;
	margin-top: 24px;
	span {
		color: ${color.primaryColor};
	}
`;
const SubmitButtonContainer = styled.div`
	display: none;
	@media ${devices.tablet} {
		align-items: center;
		display: flex;
		gap: 16px;
		justify-content: end;
		margin-top: 16px;
		padding-bottom: 2px;
		padding-right: 2px;
		width: 100%;
	}
`;

const BottomContainer = styled.div`
	align-items: center;
	background-color: #fff;
	bottom: 0;
	box-shadow: 0 -1px 10px 0 rgba(0, 0, 0, 0.06);
	display: flex;
	gap: 16px;
	justify-content: center;
	padding: 20px 16px;
	position: fixed;
	width: 100%;
	@media ${devices.tablet} {
		display: none;
	}
`;

enum FormField {
	firstName = 'firstName',
	lastName = 'lastName',
	wechat = 'wechat',
	countryCode = 'countryCode',
	mobile = 'mobile',
	email = 'email',
	information = 'information'
}

const infoLists: {
	title: string;
	subtitle: string;
}[] = [
	{
		title: '个性化定制：',
		subtitle: '根据您的职业目标和背景，我们为您量身定制职业规划，助您找到最适合的职业路径'
	},
	{
		title: '行业导师：',
		subtitle: '我们拥有丰富的行业经验和专业知识的导师团队，为您提供真实可行的职业建议'
	},
	{
		title: '免费咨询：',
		subtitle: '咨询完全免费，无论您选择线上还是线下，我们都为您提供优质的咨询服务。'
	},
	{
		title: '专业建议：',
		subtitle: '我们将根据您的背景和需求，提供实用的职业建议和行业动态，助您做出明智的职业决策。'
	}
];

interface Option {
	id: string;
	name: string;
	phase?: number;
}
interface ConsultFormProps {
	onFinish?: (success: boolean) => void;
	onCancel?: () => void;
	/** 关联课程信息 */
	option?: Option;
}

const ConsultForm = ({ onFinish, onCancel, option }: ConsultFormProps) => {
	// values
	const [formValues, setFormValues] = useState<FormValues<FormField>>({});
	const [checkValues, setCheckValues] = useState<CheckValues<FormField>>({
		firstName: true,
		lastName: true,
		email: true,
		mobile: true,
		wechat: true
	});
	const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

	useEffect(() => {
		const hasErrors = Object.values(checkValues);
		if (hasErrors.length === 0) {
			setSubmitDisabled(true);
			return;
		}
		if (hasErrors.some(item => !!item)) {
			setSubmitDisabled(true);
			return;
		}
		setSubmitDisabled(false);
	}, [checkValues]);

	// submit
	const handleSubmit = async () => {
		// 检查表单错误
		if (Object.values(checkValues).some(item => !!item)) {
			return;
		}
		try {
			await fetchQuoteCreate({
				source: UserSource.JIANGREN,
				name: {
					first: formValues.firstName || '',
					last: formValues.lastName || ''
				},
				mobile: `${formValues.countryCode || ''} ${formValues.mobile || ''}`,
				email: formValues.email || '',
				wechat: formValues.wechat || '',
				information: formValues.information || '',
				agreeContactByWechat: true,
				complete: true,
				targetCourses: option
					? `${option.name}: ${option?.phase || ''} (id: ${option.id})`
					: ''
			});

			if (onFinish) {
				onFinish(true);
			}
		} catch (err) {
			if (onFinish) {
				onFinish(false);
			}
		}
	};

	return (
		<Container>
			<IntroContainer>
				<MouseWrapper>
					<Image
						src="/image/background-stars.svg"
						loader={imageLoader}
						alt="star-background"
						width={314}
						height={36}
						unoptimized
					/>
					<Highlight>Talk to a Career Expert</Highlight>
					<TitleContainer>
						<Title>1对1免费职业咨询</Title>
						<Image
							src="/icon/home/star.svg"
							alt="star"
							unoptimized
							loader={imageLoader}
							width={30}
							height={30}
						/>
					</TitleContainer>
					<Subtitle>Supercharge your career with in-demand skills</Subtitle>
					{infoLists.map(item => (
						<InfoList key={item.title}>
							<Image
								src="/icon/feature.svg"
								loader={imageLoader}
								alt="star-background"
								width={16}
								height={16}
								style={{ margin: '5px 10px 0 0' }}
								unoptimized
							/>
							<span>
								<b>{item.title}</b>
								{item.subtitle}
							</span>
						</InfoList>
					))}
					<MouseIcon>
						<Image
							src="/icon/mouse-icon.svg"
							loader={imageLoader}
							alt="star-background"
							width={23}
							height={32}
							unoptimized
						/>
					</MouseIcon>
				</MouseWrapper>
			</IntroContainer>
			<ConsultContainer>
				<FormContainer>
					<FormItemName
						required
						values={
							[
								formValues[FormField.firstName],
								formValues[FormField.lastName]
							] as string[]
						}
						onChange={values => {
							setFormValues({
								...formValues,
								[FormField.firstName]: values[0],
								[FormField.lastName]: values[1]
							});
						}}
						onCheck={hasErrors => {
							setCheckValues({
								...checkValues,
								[FormField.firstName]: hasErrors[0],
								[FormField.lastName]: hasErrors[1]
							});
						}}
					/>
					<FormItemCom
						required
						label="微信号"
						value={formValues[FormField.wechat]}
						onChange={value => {
							setFormValues({
								...formValues,
								[FormField.wechat]: value
							});
						}}
						onCheck={hasError =>
							setCheckValues({
								...checkValues,
								[FormField.wechat]: hasError
							})
						}
					/>
					<FormItemPhone
						required
						values={
							[
								formValues[FormField.countryCode],
								formValues[FormField.mobile]
							] as string[]
						}
						onChange={values => {
							setFormValues({
								...formValues,
								[FormField.countryCode]: values[0],
								[FormField.mobile]: values[1]
							});
						}}
						onCheck={hasError =>
							setCheckValues({
								...checkValues,
								[FormField.mobile]: hasError
							})
						}
					/>
					<FormItemCom
						required
						value={formValues[FormField.email]}
						onChange={value =>
							setFormValues({ ...formValues, [FormField.email]: value })
						}
						onCheck={hasError =>
							setCheckValues({
								...checkValues,
								[FormField.email]: hasError
							})
						}
						type="email"
						label="邮箱"
					/>
					<FormItemCom
						value={formValues[FormField.information]}
						onChange={value =>
							setFormValues({ ...formValues, [FormField.information]: value })
						}
						onCheck={hasError =>
							setCheckValues({
								...checkValues,
								[FormField.information]: hasError
							})
						}
						label="备注信息"
						multiline
						maxRows={4}
					/>
					<AggerDescription>
						提交该表单将默认您同意匠人学院的
						<Link href="/privacy-policy">
							<span>隐私政策</span>
						</Link>
						和
						<Link href="/terms-and-conditions">
							<span>服务说明</span>
						</Link>
					</AggerDescription>
				</FormContainer>
				<SubmitButtonContainer>
					{onCancel && (
						<Button color="white" onClick={onCancel}>
							返回
						</Button>
					)}
					<Button disable={submitDisabled} onClick={handleSubmit}>
						立即预约免费职业咨询
					</Button>
				</SubmitButtonContainer>
			</ConsultContainer>
			<BottomContainer>
				<Button
					color="white"
					onClick={onCancel}
					style={{
						width: '100%',
						maxWidth: '40%'
					}}
				>
					返回
				</Button>
				<Button onClick={handleSubmit} disable={submitDisabled} style={{ width: '100%' }}>
					立即预约免费职业咨询
				</Button>
			</BottomContainer>
		</Container>
	);
};

export default ConsultForm;
