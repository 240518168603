import { ChangeEvent } from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';

const OutlinedTextArea = styled.textarea<{ hasError: boolean }>`
	background-color: #fff;
	border: solid 1px #000;
	border-color: ${props => (props.hasError ? color.primaryColor : '#000')};
	border-radius: 2px;
	font-size: 14px;
	padding: calc(12px - 3px) calc(16px - 1px);
	width: 100%;
	:focus {
		border: solid 2px #000;
		outline: none;
		padding: calc(12px - 4px) calc(16px - 2px);
	}
	::placeholder {
		color: #ccc;
	}
`;

interface InputProps {
	value: string;
	onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	onBlur: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	placeholder?: string;
	warning?: string;
	maxRows?: number;
}

const TextArea = ({ value, onChange, onBlur, placeholder, warning, maxRows }: InputProps) => {
	return (
		<OutlinedTextArea
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			placeholder={placeholder}
			hasError={!!warning}
			rows={maxRows || 2}
		/>
	);
};

export default TextArea;
