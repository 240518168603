import {
	MenuItem,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
	SelectChangeEvent
} from '@mui/material';
import styled from 'styled-components';

import { getMenuItemStyles } from '@/utils/style';

const StyledSelect = styled(MuiSelect)`
	ul {
		display: flex;
		flex-direction: column;
	}
`;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface SelectProps extends MuiSelectProps<string> {
	value: string;
	options: {
		label: string;
		value: string;
	}[];
	onChange: (e: SelectChangeEvent<string>) => void;
	placeholder?: string;
}

const Select = ({ value, options, onChange, placeholder, ...props }: SelectProps) => {
	return (
		<StyledSelect
			value={value}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			onChange={onChange}
			displayEmpty
			inputProps={{ 'aria-label': 'Without label' }}
			placeholder={placeholder}
			{...props}
		>
			{options.map(option => {
				return (
					<MenuItem key={option.value} value={option.value} style={getMenuItemStyles()}>
						{option.label}
					</MenuItem>
				);
			})}
		</StyledSelect>
	);
};

export default Select;
