import Image from 'next/image';
import styled from 'styled-components';

import { MODAL_STATUS } from '@/constants/common';
import imageLoader from '@/utils/loader';

const ContentContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 116px;
	margin-top: 32px;
	width: 100%;
`;
const FeedbackContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 24px;
`;
const FeedbackTitleContainer = styled.div`
	align-items: center;
	display: flex;
	font-size: 18px;
	font-weight: 500;
	gap: 10px;
	line-height: 1;
	margin: 0;
`;
const FeedbackDescription = styled.p`
	font-size: 14px;
	margin: 0;
`;

const FeedbackContent = ({ status }: { status: MODAL_STATUS }) => {
	return (
		<ContentContainer>
			<Image
				src="/image/metaverse/meta-image-hello.webp"
				alt="hello"
				unoptimized
				loader={imageLoader}
				width={98}
				height={92}
			/>
			{status === MODAL_STATUS.SUCCESS && (
				<FeedbackContainer>
					<FeedbackTitleContainer>
						<Image
							src="/icon/success.svg"
							alt="success"
							unoptimized
							loader={imageLoader}
							width={22}
							height={22}
						/>
						提交成功
					</FeedbackTitleContainer>
					<FeedbackDescription>
						课程顾问将尽快联系您，关闭此弹窗继续浏览相关课程内容
					</FeedbackDescription>
				</FeedbackContainer>
			)}
			{status === MODAL_STATUS.FAIL && (
				<FeedbackContainer>
					<FeedbackTitleContainer>
						<Image
							src="/icon/fail.svg"
							alt="fail"
							unoptimized
							loader={imageLoader}
							width={22}
							height={22}
						/>
						提交失败
					</FeedbackTitleContainer>
					<FeedbackDescription>请检查网络状态后，重新尝试</FeedbackDescription>
				</FeedbackContainer>
			)}
		</ContentContainer>
	);
};

export default FeedbackContent;
