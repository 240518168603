import { useState } from 'react';

import ConsultButton from './ConsultButton';
import ConsultForm from './ConsultForm';
import Feedback from './Feedback';
import { ModalTheme } from '@/components/UI/constants';
import Modal, { ModalContentProps, ModalTriggerProps } from '@/components/UI/Modal';
import { MODAL_STATUS } from '@/constants/common';

export enum ConsultModalStep {
	Consult = 'consult',
	ChooseFeedback = 'feedback'
}

const ConsultModal = () => {
	const [step, setStep] = useState<ConsultModalStep>(ConsultModalStep.Consult);
	const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
	const renderTrigger = (props: ModalTriggerProps) => <ConsultButton onClick={props.onClick} />;
	const renderContent = (props: ModalContentProps) => {
		switch (step) {
			case ConsultModalStep.ChooseFeedback:
				return (
					<Feedback
						status={submitSuccess ? MODAL_STATUS.SUCCESS : MODAL_STATUS.FAIL}
						onClose={props.onCancel}
					/>
				);
			default:
				return (
					<ConsultForm
						onFinish={(success: boolean) => {
							setSubmitSuccess(success);
							setStep(ConsultModalStep.ChooseFeedback);
						}}
					/>
				);
		}
	};
	return (
		<Modal
			theme={ModalTheme.Primary}
			triggerRender={renderTrigger}
			contentRender={renderContent}
			title="1v1免费咨询"
			bgTheme={ModalTheme.Primary}
			mobileTheme={ModalTheme.Primary}
		/>
	);
};

export default ConsultModal;
