import { UserRole } from '@/constants/user';
import { color } from '@/styles/variables';

export const getMenuItemStyles = (active: boolean = false) => {
	return {
		...(active && { fontWeight: 600, backgroundColor: '#f5f5f5' }),
		display: 'flex',
		fontSize: 14,
		justifyContent: 'flex-start',
		minHeight: 'unset',
		padding: '6px 16px'
	};
};

export const getAdminColor = (role: UserRole) => {
	if (role === UserRole.TEACHER) {
		return color.primaryColor;
	}
	return color.adminColor;
};

export default {};
