import { upperFirst } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import FormItemContainer from '../common/FormItemContainer';
import Input from '../common/Input';

const Container = styled.div`
	display: flex;
	gap: 16px;
	justify-content: space-between;
	width: 100%;
`;

interface FormItemNameProps {
	required?: boolean;
	values: string[];
	onChange: (value: string[]) => void; // first, last
	onCheck: (hasError: boolean[]) => void; // first, last
	style?: React.CSSProperties;
}

const MAX_ENG_NAME_LENGTH = 30;
const MAX_CH_NAME_LENGTH = 10;

const isChinese = (name: string) => /[\u4e00-\u9fa5]/.test(name);

const formatName = (name: string) => {
	return isChinese(name) ? name : upperFirst(name);
};

const validateName = (
	firstName: string,
	lastName: string,
	maxLength: number,
	isChineseName: boolean,
	setFirstWarning: (message: string) => void,
	setLastWarning: (message: string) => void
) => {
	const invalidChars = /[.,<>?\\';:~`!@#$%^&*()_+=[\]{}|]/;
	const spacePattern = /\s/;

	// calculate firstName and lastName total length
	const totalLength = firstName.length + lastName.length;
	if (totalLength > maxLength) {
		const warningMessage = isChineseName
			? `中文名字总长度不能超过${maxLength}个字符`
			: `英文名字总长度不能超过${maxLength}个字符`;
		setFirstWarning(warningMessage);
		setLastWarning(warningMessage);
		return true;
	}

	// set validate name rules
	const nameRules = (name: string, setWarning: (message: string) => void) => {
		if (!name) {
			setWarning('输入不能为空');
			return true;
		}
		if (/^\d/.test(name)) {
			setWarning('名字不能以数字开头');
			return true;
		}
		if (invalidChars.test(name)) {
			setWarning('名字不能包含特殊字符');
			return true;
		}
		if (spacePattern.test(name)) {
			setWarning('名字中不能有空格');
			return true;
		}
		setWarning('');
		return false;
	};

	const firstError = nameRules(firstName, setFirstWarning);
	const lastError = nameRules(lastName, setLastWarning);

	return firstError || lastError;
};

const FormItemName = ({ values, required, onChange, onCheck, style }: FormItemNameProps) => {
	const [thisFirstName, setThisFirstName] = useState<string>('');
	const [thisLastName, setThisLastName] = useState<string>('');

	const [firstWarning, setFirstWarning] = useState<string>('');
	const [lastWarning, setLastWarning] = useState<string>('');

	const handleCheck = (v: string[]) => {
		const isChineseName = isChinese(v[0]) || isChinese(v[1]);
		const maxLength = isChineseName ? MAX_CH_NAME_LENGTH : MAX_ENG_NAME_LENGTH;

		const hasError = validateName(
			v[0],
			v[1],
			maxLength,
			isChineseName,
			setFirstWarning,
			setLastWarning
		);
		onCheck([hasError, hasError]);
	};

	useEffect(() => {
		if (
			(values[0] || values[1]) &&
			(values[0] !== thisFirstName || values[1] !== thisLastName)
		) {
			setThisFirstName(values[0] || '');
			setThisLastName(values[1] || '');
			handleCheck(values);
		}
	}, [values]);

	const handleChange = (v: string[]) => {
		setThisFirstName(v[0]);
		setThisLastName(v[1]);

		if (onChange) {
			onChange(v);
		}
	};

	const handleBlur = (v: string[]) => {
		const formattedFirstName = formatName(v[0]);
		const formattedLastName = formatName(v[1]);

		setThisFirstName(formattedFirstName);
		setThisLastName(formattedLastName);

		handleCheck([formattedFirstName, formattedLastName]); // check on blur

		if (onChange) {
			onChange([formattedFirstName, formattedLastName]);
		}
	};

	return (
		<Container style={style}>
			<FormItemContainer required={required} label="First Name" warning={firstWarning}>
				<Input
					value={thisFirstName}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange([e.target.value, thisLastName])
					}
					onBlur={(e: ChangeEvent<HTMLInputElement>) =>
						handleBlur([e.target.value, thisLastName])
					}
					warning={firstWarning}
				/>
			</FormItemContainer>
			<FormItemContainer required={required} label="Last Name" warning={lastWarning}>
				<Input
					value={thisLastName}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleChange([thisFirstName, e.target.value])
					}
					onBlur={(e: ChangeEvent<HTMLInputElement>) =>
						handleBlur([thisFirstName, e.target.value])
					}
					warning={lastWarning}
				/>
			</FormItemContainer>
		</Container>
	);
};

export default FormItemName;
