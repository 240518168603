import { UserSource } from '@/constants/user';
import request, { HTTP_METHOD } from '@/utils/request';

interface CreateQuotePayload {
	source: UserSource;
	name: {
		first: string;
		last: string;
	};
	wechat: string;
	mobile: string;
	email?: string;
	information?: string;
	targetCourses?: string;
	agreeContactByWechat?: boolean;
	complete: boolean;
	sales?: string;
}

export const fetchQuoteCreate = async (payload: CreateQuotePayload) => {
	return request(HTTP_METHOD.POST, '/quote', {
		body: payload
	});
};

export default {};
