export enum ONBOARDING_STEP {
	/** 头像 */
	AVATAR = 'avatar',
	/** 位置 */
	LOCATION = 'location',
	LINKEDIN = 'linkedin',
	GITHUB = 'github',
	/** 个人网站 */
	WEB_SITE = 'web-site',
	/** 状态 */
	STATUS = 'status',
	/** 如何了解到 */
	HOW_TO_KNOW = 'how-to-know',
	/** 学习目标 */
	TARGET = 'target',
	/** 拥有技能 */
	SKILLS = 'skills',
	/** 结果页面 */
	FINISH = 'finish'
}

export const STUDY_ONBOARDING_STEPS = [
	ONBOARDING_STEP.AVATAR,
	ONBOARDING_STEP.LOCATION,
	ONBOARDING_STEP.LINKEDIN,
	ONBOARDING_STEP.GITHUB,
	ONBOARDING_STEP.WEB_SITE,
	ONBOARDING_STEP.STATUS,
	ONBOARDING_STEP.HOW_TO_KNOW,
	ONBOARDING_STEP.TARGET,
	ONBOARDING_STEP.SKILLS,
	ONBOARDING_STEP.FINISH
];
export const EVENT_ONBOARDING_STEPS = [
	ONBOARDING_STEP.LOCATION,
	ONBOARDING_STEP.LINKEDIN,
	ONBOARDING_STEP.STATUS,
	ONBOARDING_STEP.TARGET,
	ONBOARDING_STEP.FINISH
];
export enum ONBOARDING_TYPE {
	STUDY = 'study',
	EVENT = 'event'
}
export const ONBOARDING_STEPS = {
	[ONBOARDING_TYPE.STUDY]: STUDY_ONBOARDING_STEPS,
	[ONBOARDING_TYPE.EVENT]: EVENT_ONBOARDING_STEPS
};

export default {};
