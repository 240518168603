import { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';

import { color } from '@/styles/variables';

const OutlinedInput = styled.input<{ hasError: boolean }>`
	background-color: #fff;
	border: solid 1px #000;
	border-color: ${props => (props.hasError ? color.primaryColor : '#000')};
	border-radius: 2px;
	font-size: 14px;
	padding: calc(12px - 3px) calc(16px - 1px);
	width: 100%;
	:focus {
		border: solid 2px ${color.primaryColor};
		outline: none;
		padding: calc(12px - 4px) calc(16px - 2px);
	}
	::placeholder {
		color: #ccc;
	}
`;

interface InputProps {
	value: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onBlur: (e: ChangeEvent<HTMLInputElement>) => void;
	placeholder?: string;
	warning?: string;
	type?: HTMLInputTypeAttribute;
}

const Input = ({ value, onChange, onBlur, placeholder, warning, type }: InputProps) => {
	return (
		<OutlinedInput
			value={value}
			onChange={onChange}
			onBlur={onBlur}
			placeholder={placeholder}
			hasError={!!warning}
			type={type}
		/>
	);
};

export default Input;
